import { groq } from 'next-sanity';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Disclaimer from '../../../../components/Disclaimer';
import Layout from '../../../../components/Layout';
import PortableText from '../../../../components/PortableText';
import HeroCTA from '../../../../components/HeroCTA';
import Section from '../../../../components/Section';
import CampaignCard from '../../../../components/cards/CampaignCard';
import { carBrandPageQuery } from '../../../../groq/carBrand';
import { sanityClient } from '../../../../lib/sanity.server';
import {
  ArticleCardType,
  CampaignEntryType,
  CampaignSingularType,
  CarBrandType,
  DefaultSEOType,
  ImageWithAltType,
  MenuType,
  RelatedDealerType,
  RelatedModelType,
  SettingsType,
} from '../../../../types';
import fetchSanityData from '../../../../utils/fetchSanityData';
import CampaignBox from '../../../../components/CampaignBox';

interface PageProps {
  data: {
    menus: MenuType;
    settings: SettingsType;
    content: CarBrandType & {
      relatedModels: RelatedModelType[];
      relatedArticlesFeature: ArticleCardType[];
      defaultSEO: DefaultSEOType;
    };
  };
}

type ModelCampaignType = {
  carModel: any;
  id: string;
  title: string;
  pitch: string;
  overline: string;
  image: ImageWithAltType;
  isFeatured: boolean;
};

function CarBrandPage({ data }: PageProps) {
  const { content, menus, settings } = data;

  const {
    _id,
    relatedModels,
    bodyText,
    description,
    image,
    overline,
    slug,
    title,
    disclaimer,
    formOverride,
    relatedDealers,
    seo,
    defaultSEO,
  } = content;

  const [campaigns, setCampaigns] = useState<
    | {
      campaignsCategory: CampaignSingularType[] | undefined;
      campaignsProduct: ModelCampaignType[] | undefined;
      campaignEntry: CampaignEntryType;
    }
    | undefined
  >(undefined);

  const router = useRouter();
  const [dealers, setDealers] = useState<RelatedDealerType[] | undefined>(relatedDealers);
  useEffect(() => {
    const fetchDealers: (query: string, params: { [key: string]: string }) => void = async (
      query,
      params,
    ) => {
      const response = await fetchSanityData(query, params);

      setDealers(response);
    };

    if (router.query.dealerId) {
      fetchDealers('dealerById', { dealerId: router.query.dealerId as string });
    }
  }, [router.isReady, router.query.dealerId]);

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('carBrandCampaigns', { id: _id, slug });
      setCampaigns(response.campaigns);
    };
    fetchCampaigns();
  }, [_id, slug]);

  let disclaimerNo = 1;

  const campaignProductIds = campaigns?.campaignsProduct?.map((item) => item.id);

  const campaignModels = relatedModels
    .filter((model) => campaignProductIds?.includes(model._id))
    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
  const remainingModels = relatedModels
    .filter((model) => !campaignProductIds?.includes(model._id))
    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));

  const sortedModels = campaignModels.concat(remainingModels);

  const sortedCampaignProducts = campaigns?.campaignsProduct?.sort((a, b) =>
    a?.carModel?.toLowerCase() > b?.carModel?.toLowerCase() ? 1 : -1,
  );

  const sortedModelsTemporary = relatedModels.sort((a, b) =>
    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
  );

  const campaignDisclaimer = campaigns?.campaignsCategory?.filter((campaign) => campaign.disclaimer)
    .length
    ? `${campaigns.campaignsCategory
      .filter((item) => item.disclaimer)
      .map(
        (campaign, index) => `${new Array(index + 1).fill('*').join('')}${campaign.disclaimer}`,
      )
      .join('\n')}\n\n`
    : '';

  const getModelHref = (id: string) => {
    return campaignModels.find((model) => model._id === id)?.href;
  };

  return (
    <Layout settings={settings} seo={seo} menus={menus} defaultSEO={defaultSEO}>
      <HeroCTA
        overline={overline}
        title={title}
        description={description}
        image={image}
        primaryButton={{
          href:
            Array.isArray(dealers) && dealers.length === 1
              ? `/forhandler/${dealers[0].slug}`
              : `/forhandler?b=sale_${title}`,
          linkText: 'Finn forhandler',
        }}
        formOverride={formOverride}
      />
      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}
      {/* KAMPANJER */}

      {(!!campaigns?.campaignsCategory?.length || !!campaigns?.campaignsProduct?.length) && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Kampanjer</h2>
          {!!campaigns?.campaignsCategory?.length &&
            campaigns?.campaignsCategory?.map((campaign) => {
              return (
                <CampaignBox
                  key={campaign._id}
                  campaign={campaign}
                  itemNo={campaign.disclaimer ? disclaimerNo++ : 0}
                />
              );
            })}

          {!!campaigns?.campaignsProduct?.length &&
            <div className="grid gap-5">
              {sortedCampaignProducts?.map((obj) => (
                <CampaignCard
                  key={obj.id}
                  description={obj.pitch}
                  title={obj.title}
                  href={getModelHref(obj.id) || ''}
                  overline={obj.overline}
                  image={obj.image}
                // isFeatured={model.brand}
                />
              ))}
            </div>
          }
        </Section>
      )}

      <Section>
        <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{title}-modeller</h2>

        {/**
         * TEMPORARY: Do not render models with campaign on top
         * When ready to do so, simply loop over sortedModels instead of sortedModelsTemporary
         *  */}
        {Array.isArray(sortedModelsTemporary) && !!sortedModelsTemporary.length && (
          <div className="grid gap-5">
            {sortedModelsTemporary.map((model) => {
              // Code below filters away models that is featured
              // *****************************************************
              // const isFeatured = campaigns?.campaignsProduct?.find(
              //   (item) => item.id === model._id && item.isFeatured,
              // );
              // if (isFeatured) return null;
              // *****************************************************

              return (
                <CampaignCard
                  key={model._id}
                  description={model.description}
                  title={model.title}
                  href={model.href}
                  image={model.image}
                // isCampaign={campaignProductIds?.includes(model._id)}
                />
              );
            })}
          </div>
        )}
      </Section>
      <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
        <Disclaimer text={`${campaignDisclaimer}${disclaimer || ''}`} />
      </div>
    </Layout>
  );
}

export async function getStaticPaths() {
  const paths = await sanityClient.fetch(
    groq`*[_type == "carBrand" && defined(slug.current) ][].slug.current`,
  );
  return {
    paths: paths.map((carBrand: string) => ({ params: { carBrand } })),
    fallback: 'blocking',
  };
}

export const getStaticProps = async ({ params }: { params: { carBrand: string } }) => {
  const { carBrand = '' } = params;
  const data = await sanityClient.fetch(carBrandPageQuery, { carBrand });
  if (!data.content) return { notFound: true };

  return {
    props: {
      data,
    },
    revalidate: 1,
  };
};

export default CarBrandPage;
